import { Vue } from "vue-property-decorator";
import { JSONToCSVConvertor, TableToExcel } from '@/assets/scripts/helper';

export class PageBase extends Vue {

    public emailRules = [v => !!v && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail precisa ser válido']
    public fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    public numberFieldRules: any[] = [(v: any) => (!isNaN(parseFloat(v)) && v >= 0) || "Campo obrigatório"];

    public mskCnpj = process.env.VUE_APP_MSK_CNPJ;
    public mskCpf = process.env.VUE_APP_MSK_CPF;
    public mskTelefone = process.env.VUE_APP_MSK_TELEFONE;
    public mskCelular = process.env.VUE_APP_MSK_CELULAR;
    public mskCep = process.env.VUE_APP_MSK_CEP;
    
    public loading: boolean = false;

    public sessionApp: any;

    public app: {avaliadorId?: number, ongId?: number, empresa: string, perfilId?: number, usuarioId: number, login: string, email: string, usuarioFoto: any, temaEscuro: boolean, master: boolean} = {
        avaliadorId: undefined,
        ongId: undefined,
        empresa: '',
        perfilId: undefined,
        usuarioId: 0,
        login: '',
        email: '',
        usuarioFoto: null,
        temaEscuro: false,
        master: false
    };

    constructor(){
        super();

        if (!localStorage.sessionApp){
            this.$router.push({ name: 'Login' });
            return;
        }

        this.sessionApp = JSON.parse(localStorage.sessionApp);

        this.app.avaliadorId = this.sessionApp.dados.avaliadorId;
        this.app.ongId = this.sessionApp.dados.ongId;
        this.app.empresa = this.sessionApp.dados.empresa;
        this.app.perfilId = this.sessionApp.dados.perfilId;
        this.app.usuarioId = this.sessionApp.dados.usuarioId;
        this.app.login = this.sessionApp.dados.login;
        this.app.email = this.sessionApp.dados.email;
        this.app.usuarioFoto = this.sessionApp.dados.usuarioFoto;
        this.app.temaEscuro = this.sessionApp.dados.temaEscuro;
        this.app.master = this.sessionApp.dados.master;
    }

    ExportarExcel(tabela: string, worksheet?: string){
      TableToExcel(tabela, worksheet);
    }

    JsonToCSV(JSONData: JSON, ReportTitle: string, ShowLabel: boolean){
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel);
    }

    AbrirLink(url: string){
        window.open(url, 'blank')?.focus();
    }

    MudarTema(temaEscuro: boolean){
        this.app.temaEscuro = temaEscuro;
        this.sessionApp.dados.temaEscuro = temaEscuro;
        localStorage.setItem('sessionApp', JSON.stringify(this.sessionApp));
        this.$vuetify.theme.dark = temaEscuro;
    }
}