
import { PageBase } from "@/core/models/shared";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { AlertSimpleErr, AlertSimpleRes, AutenticadorService } from "../../core/services/shared"

@Component
export default class AlterarSenha extends PageBase {
    @Prop() private value!: string;
    @Prop() private texto!: string;

    dialog: boolean = false;

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Close() {
        this.dialog = false;
        this.$emit("fechou");
    }
}
