
import { DiagnostivoRespostaValorModel } from "@/core/models/model/DiagnostivoRespostaValorModel";
import { CadastroBase } from "@/core/models/shared";
import { DiagnosticoRespostaService } from "@/core/services/geral";
import { AlertSimpleErr, AlertSimpleNotification } from "@/core/services/shared/AlertService";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class CadastroDiagnostico extends CadastroBase {
    @Prop() private value!: string;
    @Prop() item!: DiagnostivoRespostaValorModel;

    diagnosticoRespostaService: DiagnosticoRespostaService = new DiagnosticoRespostaService();

    $refs!: {
        form: HTMLFormElement,
    }

    @Watch('item')
    Item() {
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;
    }

    RespostaZero(){
        this.item.valorNumerico = 0;
        if(this.item.valorNumerico != null && this.loading == false){
            this.loading = true;
            this.diagnosticoRespostaService.Responder(this.item).then(
                res => {
                    AlertSimpleNotification("Salvando...", "success");
                    this.Fechar();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Alterar(){
        if(this.item.valorNumerico != null && this.loading == false){
            this.loading = true;
            this.diagnosticoRespostaService.Responder(this.item).then(
                res => {
                    AlertSimpleNotification("Salvando...", "success");
                    this.Fechar();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Fechar(){
        this.$emit("fechou");
    }
}
