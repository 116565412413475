export class Complementar {

    nome: string = "";
    ramal: string = "";
    telefone: string = "";
    celular: string = "";
    email: string = "";
    cargo: string = "";

    constructor(model?: Complementar){

        if(!model)
            return;

        this.nome = model.nome;
        this.ramal = model.ramal;
        this.telefone = model.telefone;
        this.celular = model.celular;
        this.email = model.email;
        this.cargo = model.cargo;
    }
}