import { Usuario } from "../geral";

export class Operacao {

    usuarioId?: number;
    usuario!: Usuario;
    dataHora?: string;

    constructor(model?: Operacao){

        if(!model)
            return;

        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
        this.dataHora = model.dataHora;
    }
}