import { DiagnosticoCampo } from ".";

export class DiagnosticoCampoOpcao implements Shared.IEntity {
    
    id: number = 0;
    campoId: number = 0;
    campo!: DiagnosticoCampo;
    texto: string = "";

    constructor(model?: DiagnosticoCampoOpcao){

        if(!model)
            return;

        this.id = model.id;
        this.campoId = model.campoId;
        this.campo = model.campo;
        this.texto = model.texto;
    }
}