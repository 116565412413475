import { DiagnosticoCampo, DiagnosticoCampoOpcao, DiagnosticoResposta } from "../geral";

export class DiagnostivoRespostaValorModel{

    id: number = 0;
    diagnosticoRespostaId: number = 0;
    diagnosticoResposta!: DiagnosticoResposta;
    campoId: number = 0;
    campo!: DiagnosticoCampo;
    valor!: string;
    valorNumerico?: Number;
    opcaoId?: number;
    opcao!: DiagnosticoCampoOpcao;
    
    ultimoValor?: DiagnostivoRespostaValorModel; 

    constructor(model?: DiagnostivoRespostaValorModel){

        if(!model)
            return;

        this.id = model.id;
        this.diagnosticoRespostaId = model.diagnosticoRespostaId;
        this.diagnosticoResposta = model.diagnosticoResposta;
        this.campoId = model.campoId;
        this.campo = model.campo;
        this.valor = model.valor;
        this.valorNumerico = model.valorNumerico;
        this.opcaoId = model.opcaoId;
        this.opcao = model.opcao;
        
        this.ultimoValor = model.ultimoValor;
    }
}