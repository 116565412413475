
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Usuario } from "@/core/models/geral";
import { AlertSimpleErr, AlertSimpleRes, ArquivoService } from "@/core/services/shared";
import jiff from 'jiff';
import { PerfilUsuarioService, UsuarioService } from "@/core/services/geral";
import { CadastroBase } from "@/core/models/shared";

@Component
export default class CadastroUsuario extends CadastroBase {
  @Prop() private item!: Usuario;
  @Prop() private value!: string;

  itemOriginal!: Usuario;
  service: UsuarioService = new UsuarioService();  
  $refs!: {
    form: HTMLFormElement
  }

  perfilService: PerfilUsuarioService = new PerfilUsuarioService();
  perfis: any[] = [];
  foto: any = null;

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
      this.Carregar();
    }
  }

  @Watch("dialog")
  Dialog() {
    if (!this.dialog) {
      this.$emit("fechou");
    }
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  mounted() {

  }

  Carregar() {
    this.perfilService.ListarTudo().then(
      res=>{
        this.perfis = res.data.items;
      },
      err=> AlertSimpleErr("Aviso", err)
    )
  }

  Salvar() {
    if (this.$refs.form.validate()) {
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          AlertSimpleRes("Aviso", res);
          this.Salvou();
        },
        err => {
          AlertSimpleErr("Aviso", err);
        }
      );
    }
  }

  Salvou(){
    this.$emit("salvou");
    this.Close();
  }

  async LoadImage(){
    if (!this.foto)
      return;

    let resizeImage = require('resize-image');
    const img = document.createElement('img');  
    
    const promise = new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(this.foto);
      reader.onload = (event) => {
        (img.src as any) = (event.target as any).result;
      }
      img.onload = () => {
        let data = resizeImage.resize(img, 150, 150, resizeImage.JPEG);
        resolve(data)
      }
    });
    
    const response = await promise;
    this.item.foto = (response as any).replace(/^[^,]*,/, "");
  }  

  RemoveImage(){
    this.item.foto = null;
    this.foto = null;
  }

  Close() {
    this.foto = null;
    this.dialog = false;
  }
}
