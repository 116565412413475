

import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { PageBase } from "@/core/models/shared";
import { AlertSimpleErr } from '@/core/services/shared';

@Component
export default class RelatorioPage extends PageBase {
    @Prop() private titulo!: string;
    @Prop({default: true}) private fonteVariavel!: boolean;
    @Prop() private paisagem!: boolean;
    @Prop() private exportarExcel!: boolean;
    @Prop({default: 100}) private fonteInicial!: number;

    root: any = document.documentElement;
    // empresa: Empresa = new Empresa();
    isEmpresa: boolean = false;

    created(){
        // const empresaService = new EmpresaService();
        // if(this.app.avaliadorId){
        //     this.isEmpresa = true;
        //     empresaService.ObterPorId(this.app.avaliadorId).then(
        //         res=> {
        //             this.empresa = res.data
        //         },
        //         err=> AlertSimpleErr("Aviso!", err)
        //     )
        // }
    }

    mounted(){

        this.root = document.body;

        if(this.paisagem){
            this.root.style.setProperty("--widthPage", 29.7 + 'cm');
            this.root.style.setProperty("--min-heightPage", 19 + 'cm');
            this.setPageSize('landscape')
        }      
        else{
            this.root.style.setProperty("--widthPage", 21 + 'cm');
            this.root.style.setProperty("--min-heightPage", 26 + 'cm');
            this.setPageSize('portrait')
        }

        // INICIALIZAR FONT SIZE
        document.documentElement.style.setProperty("--fontSize", this.fonteInicial + '%');
    }

    setPageSize(size){
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${size}}`;
        document.head.appendChild(style);
    }

    AumentarFonte(){
        var raiz = getComputedStyle(this.root);
        var valor = parseFloat(raiz.getPropertyValue('--fontSize').replace("%", ""));
        valor += 5;
        document.documentElement.style.setProperty("--fontSize", valor + '%');
    }

    DiminuirFonte(){
        var raiz = getComputedStyle(this.root);
        var valor = parseFloat(raiz.getPropertyValue('--fontSize').replace("%", ""));
        valor -= 5;
        document.documentElement.style.setProperty("--fontSize", valor + '%');
    }

    Imprimir(){
        window.print();
    }

    Fechar(){
        window.close();
    }
    
}
