
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AvaliadorAvaliado, Diagnostico, DiagnosticoAvaliado, DiagnosticoCampo, DiagnosticoCampoAgrupador, DiagnosticoCampoOpcao, DiagnosticoFormulario, TipoDiagnosticoFormulario } from "@/core/models/geral";
import { AlertExcludeQuestion, AlertQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes, ArquivoService } from "@/core/services/shared";
import jiff from 'jiff';
import { AvaliadorService, DiagnosticoService, TipoDiagnosticoFormularioService, } from "@/core/services/geral";
import { CadastroBase } from "@/core/models/shared";

@Component
export default class CadastroDiagnostico extends CadastroBase {
  @Prop() private value!: string;
  @Prop({default: false}) preview!: boolean;
  @Prop() item!: Diagnostico;

  itemOriginal!: Diagnostico;
  service: DiagnosticoService = new DiagnosticoService();  
  $refs!: {
    form: HTMLFormElement,
    file: HTMLFormElement,
    formAvaliado: HTMLFormElement
  }

  tipos: TipoDiagnosticoFormulario[] = [];
  tipoDiagnosticoService: TipoDiagnosticoFormularioService = new TipoDiagnosticoFormularioService();  

  loadingDiagnosticoFoto: boolean = false;

  validAvaliado: boolean = true;
  avaliado: DiagnosticoAvaliado = new DiagnosticoAvaliado();  
  avaliados: any[] = [];
  avaliadorService: AvaliadorService = new AvaliadorService();
  avaliadoHeader: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'CNPJ', value: 'avaliado.cnpj' },
    { text: 'Nome', value: 'avaliado.nome' }
  ];

  foto: any = null;  

  @Watch('item')
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation();
    }
    if (this.$refs.formAvaliado) {
      this.$refs.formAvaliado.resetValidation();
    }    
  }

  @Watch("value")
  Value() {
    this.dialog = this.value ? true : false;

    if (this.dialog){
      this.itemOriginal = jiff.clone(this.item);
      this.Carregar();
    }
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      if(this.item.id > 0){        
        this.loadingDiagnosticoFoto = true;
        this.service.Foto(this.item.id).then(
          res => {
            if(res.data)
              this.item.foto = res.data;
          },
          err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
          this.loadingDiagnosticoFoto = false;
        })
      }
    }
    else {
      this.$emit("fechou");
    }
  }  

  mounted() {

  }

  async LoadImage(){
    this.foto = this.$refs.file.files[0];
    if (!this.foto)
      return;

    let resizeImage = require('resize-image');
    const img = document.createElement('img');  
    
    const promise = new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(this.foto);
      reader.onload = (event) => {
        (img.src as any) = (event.target as any).result;
      }
      img.onload = () => {
        let data = resizeImage.resize(img, 350, 350, resizeImage.JPEG);
        resolve(data)
      }
    });
    
    const response = await promise;
    this.item.foto = (response as any).replace(/^[^,]*,/, "");
  }

  RemoveImage(){
    this.item.foto = null;
    this.foto = null;
  }

  Carregar() {
    this.tipoDiagnosticoService.ListarTudo().then(
      res => {
        this.tipos = res.data.items;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
    this.avaliadorService.ObterPorId(this.app.avaliadorId!, "Avaliados").then(
      res => {
        this.avaliados = res.data.avaliados;
      }
    )
  }

  AdicionarFormulario(){
    var formulario = new DiagnosticoFormulario();
    formulario.diagnosticoId = this.item.id;
    formulario.tipoId = 1;
    formulario.nome = "Novo Formulário";
    this.item.formularios.push(formulario);
  }

  ExcluirFormulario(item: DiagnosticoFormulario){
    let context = this;
    const excluir = function(){
      return new Promise(async function (resolve, reject){
        const index = context.item.formularios.indexOf(item);
        context.item.formularios.splice(index, 1);
      })
    };
    AlertExcludeQuestion(excluir, true);
  }

  AdicionarAgrupador(item: DiagnosticoFormulario){
    var agrupador = new DiagnosticoCampoAgrupador();
    agrupador.nome = "Novo Agrupador";
    item.agrupadores.push(agrupador);
  }

  ExcluirAgrupador(formulario: DiagnosticoFormulario, agrupador: DiagnosticoCampoAgrupador){
    let context = this;
    const excluir = function() {
      return new Promise(async function (resolve, reject){
        if(agrupador.id > 0){
          context.service.ExcluirFormularioAgrupador(context.item.id, formulario.id, agrupador.id).then(
            res => {
              AlertSimpleRes("Aviso!", res);
              const index = formulario.agrupadores.indexOf(agrupador);
              formulario.agrupadores.splice(index, 1);
            }
          )
        }
        else{
          const index = formulario.agrupadores.indexOf(agrupador);
          formulario.agrupadores.splice(index, 1);
        }        
      })
    }
    AlertExcludeQuestion(excluir, true);
  }

  AdicionarCampo(item: DiagnosticoCampoAgrupador){
    var campo = new DiagnosticoCampo();
    campo.label = "Novo Campo";
    item.campos.push(campo);
  }

  ExcluirCampo(agrupador: DiagnosticoCampoAgrupador, campo: DiagnosticoCampo){
    let context = this;
    const excluir = function() {
      return new Promise(async function (resolve, reject){
        if(campo.id > 0){
          context.service.ExcluirFormularioCampo(context.item.id, agrupador.formularioId, agrupador.id, campo.id).then(
            res => {
              AlertSimpleRes("Aviso!", res)
              const index = agrupador.campos.indexOf(campo);
              agrupador.campos.splice(index, 1);
            },
            err => AlertSimpleErr("Aviso!", err)
          )
        }
        else{
          const index = agrupador.campos.indexOf(campo);
          agrupador.campos.splice(index, 1);
        }
      })
    }
    AlertExcludeQuestion(excluir, true);
  }

  AdicionarCampoOpcao(item: DiagnosticoCampo){
    var opcao = new DiagnosticoCampoOpcao();
    opcao.campoId = item.id;
    if(!item.opcoes)
      item.opcoes = [];
    item.opcoes.push(opcao);
  }

  ExcluirCampoOpcao(campo: DiagnosticoCampo, opcao: DiagnosticoCampoOpcao){
    let context = this;
    const excluir = function() {
      return new Promise(async function (resolve, reject){
        if(campo.id > 0){
          var agrupador = new DiagnosticoCampoAgrupador();
          context.item.formularios.forEach(formulario => {
            formulario.agrupadores.forEach(formularioAgrupador => {
              if(formularioAgrupador.id == campo.agrupadorId){
                agrupador = formularioAgrupador;
              }
            });
          });
          context.service.ExcluirFormularioCampoOpcao(context.item.id, agrupador.formularioId, campo.agrupadorId, campo.id, opcao.id).then(
            res => {
              AlertSimpleRes("Aviso!", res)
              const index = campo.opcoes.indexOf(opcao);
              campo.opcoes.splice(index, 1);
            },
            err => AlertSimpleErr("Aviso!", err)
          )
        }
        else{
          const index = campo.opcoes.indexOf(opcao);
          campo.opcoes.splice(index, 1);
        }
      })
    }
    AlertExcludeQuestion(excluir, true);
  }

  AdicionarAvaliado(){
    if (this.$refs.formAvaliado.validate()) {
      if(this.item.avaliados.find(x => x.avaliadoId == this.avaliado.avaliadoId)){
        AlertSimple("Aviso!", "Avaliado já adicionado!", "warning");
      }
      else{
        this.avaliado.diagnosticoId = this.item.id;
        this.avaliado.avaliado = this.avaliados.find(x => x.id == this.avaliado.avaliadoId)!;
        this.item.avaliados.push(this.avaliado);
      }
      this.avaliado = new DiagnosticoAvaliado();
    }
  }

  ExcluirAvaliado(item: DiagnosticoAvaliado){
    let context = this;
    const excluir = function() {
      return new Promise(async function (resolve, reject){
        const index = context.item.avaliados.indexOf(item);
        context.item.avaliados.splice(index, 1);
      })
    }
    AlertExcludeQuestion(excluir, true);
  }

  Fechar(){
    this.item = new Diagnostico();
    this.Close();
    this.$emit("fechou");
  }

  Salvar(silent: boolean = true) {
    if (this.$refs.form.validate()) {
      this.loading = true;
      let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
      (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
          if(res.data.id)
            this.item.id = res.data.id;
          if(silent){
            AlertSimpleRes("Aviso", res);
            this.Salvou();
          }
        },
        err => {
          AlertSimpleErr("Aviso", err);
        }
      ).finally(() => {
        this.loading = false;
      });
    }
  }

  // PREVIEW
  Preview(){
    this.preview = true;
    this.Salvar(false);
  }

  VoltarPreview(){
    this.preview = false;
  }

  Publicar(){
    const context = this;
    const publicar = function() {
      return new Promise(async function (resolve, reject){
        context.service.Publicar(context.item.id).then(
          res => {
            AlertSimpleRes("Aviso!", res);
            context.Salvou();
          },
          err => AlertSimpleErr('Aviso!', err)
        )
      })
    }
    AlertQuestion("Aviso!", "Deseja realmente publicar o diagnóstico? Após a publicação, não poderá realizar mais nenhuma alteração.", publicar);
  }
}
