
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { CadastroBase } from "@/core/models/shared";
import { AlertQuestion, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared";
import { Diagnostico, DiagnosticoResposta } from "@/core/models/geral";
import { DiagnosticoRespostaService } from "@/core/services/geral";

@Component
export default class CadastroAvaliadoDiagnostico extends CadastroBase {
    @Prop({required: true}) private diagnostico!: Diagnostico;
    @Prop({required: true}) private resposta!: DiagnosticoResposta;
    @Prop() private value!: boolean;
    
    $refs!: {
        form: HTMLFormElement
    }

    dataCompetencia: string = new Date().toLocaleString("sv-SE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"        
    }).replace(" ", "T");
    enviar: boolean = false;
    service: DiagnosticoRespostaService = new DiagnosticoRespostaService();

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            console.log(this.resposta);
        }
        else{
            this.$emit("fechou");
        }
    }

    Carregar(){
        
    }

    Fechar(){
        this.Close();
    }

    Enviar() {
        if (this.$refs.form.validate()) {
            this.service.Enviar(this.resposta.id, this.dataCompetencia).then(
                res =>{
                    AlertSimpleRes("Aviso!", res);
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso!", err);                    
                }
            )
        }
    }
}
