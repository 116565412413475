import { DiagnosticoCampoAgrupador, DiagnosticoCampoOpcao } from ".";

export class DiagnosticoCampo implements Shared.IEntity {
    
    id: number = 0;
    agrupadorId: number = 0;
    agrupador!: DiagnosticoCampoAgrupador;
    label: string = "";
    texto: string = "";

    opcoes: DiagnosticoCampoOpcao[] = [];    

    constructor(model?: DiagnosticoCampo){

        if(!model)
            return;

        this.id = model.id;
        this.agrupadorId = model.agrupadorId;
        this.agrupador = model.agrupador;
        this.label = model.label;
        this.texto = model.texto;

        this.opcoes = model.opcoes;
    }
}