import { Diagnostico, DiagnosticoCampoAgrupador, TipoDiagnosticoFormulario } from ".";

export class DiagnosticoFormulario implements Shared.IEntity {
    
    id: number = 0;
    diagnosticoId: number = 1;
    diagnostico!: Diagnostico;
    tipoId: number = 0;
    tipo!: TipoDiagnosticoFormulario;
    nome: string = "";
    descricao: string = "";

    agrupadores: DiagnosticoCampoAgrupador[] = [];

    constructor(model?: DiagnosticoFormulario){

        if(!model)
            return;

        this.id = model.id;
        this.diagnosticoId = model.diagnosticoId;
        this.diagnostico = model.diagnostico;
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.nome = model.nome;
        this.descricao = model.descricao;

        this.agrupadores = model.agrupadores;
    }
}