import { DiagnosticoCampo } from "./DiagnosticoCampo";
import { DiagnosticoFormulario } from "./DiagnosticoFormulario";

export class DiagnosticoCampoAgrupador implements Shared.IEntity {
    
    id: number = 0;
    formularioId: number = 0;
    formulario!: DiagnosticoFormulario;
    nome: string = "";

    campos: DiagnosticoCampo[] = [];

    constructor(model?: DiagnosticoCampoAgrupador){

        if(!model)
            return;

        this.id = model.id;
        this.formularioId = model.formularioId;
        this.formulario = model.formulario;
        this.nome = model.nome;

        this.campos = model.campos;
    }
}