import Vue from 'vue'
import VueRouter, { NavigationGuardNext, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const verificaPerfil = (next: NavigationGuardNext<Vue>, perfilPermitido: string[], isAvaliado: boolean) => {
  var sessionApp: any;
  if(localStorage.sessionApp){
    sessionApp = JSON.parse(localStorage.sessionApp);

    if(perfilPermitido.length > 0 && (perfilPermitido.find(a => a == sessionApp.dados.role) == undefined || perfilPermitido.find(a => a == sessionApp.dados.role) == null)){
      next({ name: 'Home' });
    }
    else if(sessionApp.dados.ongId && !isAvaliado){
      next({ name: 'Home' });
    }
    else{
      next();
    }
  }
  else
  {
    localStorage.clear();
    next({ name: 'Login' });
  } 
}

const routes: Array<RouteConfig> = [
  { path: '*', redirect: '/home'},
  { path: '/login', name: 'Login', component: () => import('@/views/Login.vue') },
  { path: '/home', name: 'Home', component: () => import('@/views/Home.vue') },

  //GERAL
  { path: '/geral/diagnostico', name: 'diagnostico', component: () => import('@/views/geral/diagnostico/Lista.vue'), beforeEnter: (to, from, next)  => verificaPerfil(next, [], false) },
  { path: '/geral/diagnosticoResposta', name: 'diagnosticoResposta', component: () => import('@/views/geral/diagnosticoResposta/Lista.vue'), beforeEnter: (to, from, next)  => verificaPerfil(next, [], true) },
  { path: '/geral/diagnosticoResposta/detalhar', name: 'detalharDiagnosticoResposta', component: () => import('@/views/geral/diagnosticoResposta/Detalhar.vue'), beforeEnter: (to, from, next)  => verificaPerfil(next, [], true) },
  //RELATORIO
  { path: '/relatorio/diagnostico/configuracao', name: 'relatorioDiagnosticoConfiguracao', component: () => import('@/views/relatorio/diagnostico/Configuracao.vue'), beforeEnter: (to, from, next)  => verificaPerfil(next, [], true) },
  { path: '/relatorio/diagnostico/:id', name: 'relatorioDiagnostico', component: () => import('@/views/relatorio/diagnostico/Relatorio.vue'), beforeEnter: (to, from, next)  => verificaPerfil(next, [], true) },
  //AUXILIAR
  { path: '/auxiliar/avaliador', name: 'avaliador', component: () => import('@/views/auxiliar/avaliador/Lista.vue'), beforeEnter: (to, from, next)  => verificaPerfil(next, [], false) },
  { path: '/auxiliar/usuario', name: 'usuario', component: () => import('@/views/auxiliar/usuario/Lista.vue'), beforeEnter: (to, from, next)  => verificaPerfil(next, [], false) },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
