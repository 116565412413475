import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class DiagnosticoService extends Service {

    constructor() {
        super('v1/diagnostico');
    }

    AutoComplete(q: string){
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q
            }
        });
    }

    GetByAvaliador(avaliadorId: number){
        return http.get(`${this.GetNomeControle()}/GetByAvaliador/${avaliadorId}`);
    }

    ExcluirFormularioAgrupador(id: number, formularioId: number, agrupadorId: number){
        return http.delete(`${this.GetNomeControle()}/${id}/Formulario/${formularioId}/Agrupador/${agrupadorId}`);
    }

    ExcluirFormularioCampo(id: number, formularioId: number, agrupadorId: number, campoId: number){
        return http.delete(`${this.GetNomeControle()}/${id}/Formulario/${formularioId}/Agrupador/${agrupadorId}/Campo/${campoId}`);
    }

    ExcluirFormularioCampoOpcao(id: number, formularioId: number, agrupadorId: number, campoId: number, opcaoId: number){
        return http.delete(`${this.GetNomeControle()}/${id}/Formulario/${formularioId}/Agrupador/${agrupadorId}/Campo/${campoId}/Opcao/${opcaoId}`);
    }

    Publicar(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/Publicar`);
    }

    Avaliados(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/Avaliados`);
    }

    Foto(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/Foto`);
    }

    Avaliado(){
        return http.get(`${this.GetNomeControle()}/Avaliado`);
    }

    //RELATORIO
    Relatorio(id: number, organizacaoId?: number, autoAvaliacao?: boolean){
        return http.get(`${this.GetNomeControle()}/${id}/Relatorio`, {
            params: {
                organizacaoId,
                autoAvaliacao
            }
        });
    }
}