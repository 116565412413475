<template>        
    <canvas :id="key"/>
</template>

<script>
import { Chart, registerables, Tooltip } from 'chart.js'

export default {
    props:{
        data: {
            required: true
        },
        title: {
            required: true
        }
    },
    created(){
        this.key = Math.random(36).toString();
        Chart.register(...registerables);
    },
    mounted(){
        let vm = this;
        vm.Generate();
        vm.UpdateChart();
    },
    watch:{
        data: function (){
            this.UpdateChart();
        }
    },
    data: () => ({
        chart: {},
        head: "",
        headers: [],
        values: [],
        key: 'ada4sd8a9sd7'
    }),
    methods:{
        Generate(){            

            let vm = this;
            const canvas = document.getElementById(this.key);
            const config = {
                type: 'radar',
                options: {
                    scale: {
                        min: 0,
                        max: 5
                    },
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: function(context){
                                    return vm.headers[context[0].dataIndex];
                                },
                                label: function(context){
                                    return context.raw;
                                }
                            }
                        },
                        legend: {
                            display: true,
                            labels: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black'
                            }
                        }
                    },
                    scales: {
                        y: {
                            display: false,
                            ticks: {
                                color: this.$vuetify.theme.dark ? 'white' : 'lightgrey',
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                                color: this.$vuetify.theme.dark ? "DimGray" : 'lightgray'
                            }
                        },
                        x: {
                            display: false,
                            ticks: {
                                color: this.$vuetify.theme.dark ? 'white' : 'black'                                
                            },
                            grid: {
                                drawBorder: false,
                                display: false,
                                color: this.$vuetify.theme.dark ? "DimGray" : 'lightgray'
                            }
                        }
                    }                 
                },                
            }

            this.chart = new Chart(canvas, config);
        },
        UpdateChart(){

            if(this.chart){                
                this.chart.data.datasets.length = 0;
                this.chart.data = {
                    labels : this.data.agrupadores,
                    datasets: this.data.respostas
                }
                this.chart.data.datasets.forEach((x) => {
                    x.backgroundColor = [
                        'rgba(75,  192, 192, 0.2)',
                        'rgba(255, 99,  132, 0.2)',
                        'rgba(135, 206, 235, 0.2)',
                        'rgba(205, 133, 63,  0.2)',
                        'rgba(128, 128, 128, 0.2)',
                        'rgba(0,   139, 139, 0.2)',
                        'rgba(154, 205, 50,  0.2)',
                        'rgba(54,  162, 235, 0.2)',
                        'rgba(244, 164, 96,  0.2)',
                        'rgba(0,   128, 0,   0.2)',
                        'rgba(255, 206, 86,  0.2)',
                        'rgba(70,  130, 180, 0.2)',
                        'rgba(218, 165, 32,  0.2)',
                        'rgba(153, 102, 255, 0.2)',
                    ]
                })                
                // this.chart.data = {
                //     labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio"],
                //     datasets: [
                //         {
                //             label: "Teste A",
                //             data: [5, 8, 46, 15, 37]
                //         },
                //         {
                //             label: "Teste B",
                //             data: [15, 28, 6, 35, 43]
                //         },
                //         {
                //             label: "Teste C",
                //             data: [35, 18, 26, 45, 53]
                //         }
                //     ]
                // }                
                this.chart.update();
            }
        }
    }
}
</script>