import Vue from 'vue';

import MasterPage from "./shared/MasterPage.vue";
import TextSearch from "./shared/TextSearch.vue";
import Avatar from "./shared/Avatar.vue";
import AlterarSenha from "./shared/AlterarSenha.vue";
import RelatorioPage from "./shared/RelatorioPage.vue";
import DiagnosticoCard from "./shared/DiagnosticoCard.vue"
import HelpDialog from "./shared/HelpDialog.vue"

import AreaChart from "./shared/chartJS/AreaChart.vue"
import BarChart from "./shared/chartJS/BarChart.vue"
import LineChart from "./shared/chartJS/LineCart.vue"

Vue.component("master-page", MasterPage);
Vue.component("text-search", TextSearch);
Vue.component("avatar-component", Avatar);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("relatorio-page", RelatorioPage);
Vue.component("diagnostico-card", DiagnosticoCard);
Vue.component("help-dialog", HelpDialog);

Vue.component("area-chart", AreaChart);
Vue.component("bar-chart", BarChart);
Vue.component("line-chart", LineChart);

//GERAL
import CadastroDiagnostico from "./geral/diagnostico/Cadastro.vue"
import CadastroDiagnosticoCampoResposta from "./geral/diagnostico/Resposta.vue"
import CadastroDiagnosticoCampoUltimaResposta from "./geral/diagnostico/RespostaAnterior.vue";

Vue.component("cadastro-diagnostico", CadastroDiagnostico);
Vue.component("cadastro-diagnostico-campo-resposta", CadastroDiagnosticoCampoResposta);
Vue.component("cadastro-diagnostico-campo-ultimaResposta", CadastroDiagnosticoCampoUltimaResposta);

//AVALIADO
import CadastroDiagnosticoResposta from "./avaliado/diagnostico/Cadastro.vue"

Vue.component("cadastro-diagnostico-resposta", CadastroDiagnosticoResposta);

//AUXILIAR
import CadastroUsuario from "./auxiliar/usuario/Cadastro.vue"

Vue.component("cadastro-usuario", CadastroUsuario);