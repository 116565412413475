import { Avaliador, DiagnosticoAvaliado, DiagnosticoFormulario, SituacaoDiagnostico } from ".";

export class Diagnostico implements Shared.IEntity {

    id: number = 0;
    avaliadorId: number = 0;
    avaliador!: Avaliador;
    nome: string = "";
    descricao: string = "";
    dataHoraInicio: string = "";
    validade: number = 0;
    criacao: any = {};
    publicacao?: any = {};
    cancelamento?: any = {};
    situacaoId: number = 1;
    situacao!: SituacaoDiagnostico;
    foto: any = null;
    ultimaModificacao?: string;
    respondido: boolean = false;
    alterarDadosUltimaResposa: boolean = false;

    avaliados: DiagnosticoAvaliado[] = [];
    formularios: DiagnosticoFormulario[] = [];

    constructor(model?: Diagnostico){

        if(!model)
            return;

        this.id = model.id;
        this.avaliadorId = model.avaliadorId;
        this.avaliador = model.avaliador;
        this.nome = model.nome;
        this.descricao = model.descricao;
        this.dataHoraInicio = model.dataHoraInicio;
        this.validade = model.validade;
        this.criacao = model.criacao;
        this.publicacao = model.publicacao;
        this.cancelamento = model.cancelamento;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
        this.foto = model.foto;
        this.ultimaModificacao = model.ultimaModificacao;
        this.respondido = model.respondido;
        this.alterarDadosUltimaResposa = model.alterarDadosUltimaResposa;

        this.avaliados = model.avaliados;
        this.formularios = model.formularios;
    }
}