import { http } from "@/core/ApiConfig";
import { Service } from "../shared/Service";

export class AvaliadorService extends Service {

    constructor() {
        super('v1/avaliador');
    }

    BuscarOrganizacaoSistema(q?: string, l?: string){
        return http.post(`${this.GetNomeControle()}/BuscarOrganizacaoSistema`, l, {
            params: {
                q
            }
        });
    }
}