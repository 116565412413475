import { http, httpHeader } from '@/core/ApiConfig';
import { LoginModel } from '@/core/models/shared/model';
   
export class AutenticadorService {

  private _nomeControle = 'v1/autenticador';

  public async AutenticarUsuario(model: LoginModel){
    return await http.post(`${this._nomeControle}/usuario`, model);
  }

  public async ResetarUsuario(usuarioLogin: string, tokenResetarSenha: string, novaSenhaResetarSenha: string){
    return await http.post(`${this._nomeControle}/resetarSenha`, null, {
      params:{
        usuarioLogin: usuarioLogin,
        token: tokenResetarSenha,
        novaSenha: novaSenhaResetarSenha
      },
      headers: httpHeader.headers
    });
  }

  //SENHAS
  AlterarSenha(id:number, senhaAntiga:string, senhaNova:string){
    const model = {
      usuarioId: id,
      senhaAntiga: senhaAntiga,
      senhaNova: senhaNova
    };
    return http.post(`${this._nomeControle}/AlterarSenha`, model);
  }
  
  ResetarSenha(cnpj: string, login: string){
    const model = {
      cnpj,
      login
    };
    return http.post(`${this._nomeControle}/RedefinirSenha`, model);
  }
  
  MudarTema(id: number){
    return http.put(`${this._nomeControle}/${id}/MudarTema`, null);
  }

}