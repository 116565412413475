import { Municipio, Pais } from "..";

export class Endereco {

    logradouro: string = "";
    numero: string = "";
    complemento: string = "";
    cep: string = "";
    bairro: string = "";
    municipioId: number = 0;
    municipio!: Municipio;
    paisId: number = 0;
    pais!: Pais;

    constructor(model?: Endereco){

        if(!model)  
            return;

        this.logradouro = model.logradouro;
        this.numero = model.numero;
        this.complemento = model.complemento;
        this.cep = model.cep;
        this.bairro = model.bairro;
        this.municipioId = model.municipioId;
        this.municipio = model.municipio;
        this.paisId = model.paisId;
        this.pais = model.pais;
    }
}