import { PageBase } from "./PageBase";

export class CadastroBase extends PageBase {

    public valid: boolean = true;
    public dialog: boolean = false;
    public tabActive: any = {};

    beforeUpdate(){
        if (!this.dialog){
          this.$emit('fechou');
        }
    }

    Close() {
        this.dialog = false;
        this.tabActive = {};
    }
    
    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
}
