
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { PageBase } from "@/core/models/shared";

@Component
export default class DiagnosticoCard extends PageBase {
    @Prop() foto!: any;
    @Prop() nome!: string;
    @Prop() descricao!: string;
    @Prop() dataInicio!: string;
    @Prop() validade!: number;

    dataFim: string = "";

    show: boolean = false;
    dias: number = 0;
    horas: number = 0;
    minutos: number = 0;
    segundos: number = 0;

    mounted(){
        setInterval(this.CalcularData, 1000);
    }

    CalcularData(){
        const dataReferencia = new Date(new Date(this.dataInicio).setDate(new Date(this.dataInicio).getDate() + this.validade)) as any;
        this.dataFim = new Date(dataReferencia).toDateYYYYMMDD();
        const dataAtual = new Date() as any;
        const totalSegundos = (dataReferencia - dataAtual) / 1000;
        this.dias = Math.floor(totalSegundos / 3600 / 24);
        this.horas = Math.floor(totalSegundos / 3600) % 24;
        this.minutos = Math.floor(totalSegundos / 60) % 60;
        this.segundos = Math.floor(totalSegundos) % 60;
    }
}
