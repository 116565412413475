import { Avaliador } from ".";

export class AvaliadorAvaliado implements Shared.IEntity {

    id: number = 0;
    avaliadorId: number = 0;
    avaliador!: Avaliador;
    cnpj: string = "";
    nome: string = "";

    constructor(model?: AvaliadorAvaliado){

        if(!model)
            return;

        this.id = model.id;
        this.avaliadorId = model.avaliadorId;
        this.avaliador = model.avaliador;
        this.cnpj = model.cnpj;
        this.nome = model.nome;
    }
}