
import { PageBase } from "@/core/models/shared";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { AlertSimpleErr, AlertSimpleRes, AutenticadorService } from "../../core/services/shared"

@Component
export default class AlterarSenha extends PageBase {
    @Prop() private value!: string;

    validDialog: boolean = false;
    dialog: boolean = false;
    senhaAntiga: string = '';
    senhaNova: string = '';
    repetirSenha: string = '';
    showPassword1: boolean = false;
    showPassword2: boolean = false;
    showPassword3: boolean = false;
    fieldRules: any[] = [ v => !!v || 'Campo obrigatório' ];
    service: AutenticadorService = new AutenticadorService();

    $refs!:{
        form: HTMLFormElement
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.service.AlterarSenha(this.app.usuarioId!, this.senhaAntiga, this.senhaNova).then(
                res => {
                    this.Close();
                    AlertSimpleRes("Aviso", res);
                },
                err => AlertSimpleErr("Aviso", err)
            );
        }
    }

    Limpar(){
        this.senhaAntiga = "";
        this.senhaNova = "";
        this.repetirSenha = "";
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    Close() {
        this.Limpar();
        this.dialog = false;
    }
}
