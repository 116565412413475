<template>        
    <canvas :id="key"/>
</template>

<script>
import { Chart, registerables, Tooltip } from 'chart.js'

export default {
    props:{
        headers: {
            required: true
        },
        data: {
            required: true
        }
    },
    created(){
        this.key = Math.floor(Math.random() * 9999999999).toString();
        Chart.register(...registerables);
    },
    mounted(){
        let vm = this;
        vm.Generate();
        vm.UpdateChart();
    },
    watch:{
        data: function (){
            this.UpdateChart();
        }
    },
    data: () => ({
        key: 'ada4sd8a9sd779654',
        chart: {},        
    }),
    methods:{
        Generate(){            
            let vm = this;
            const canvas = document.getElementById(this.key);            
            const config = {
                type: 'line',
                options: {
                    plugins: {
                        title: {
                            display: false                                
                        },
                        legend: {
                            display: false
                        }
                    },
                    interaction: {
                        intersect: false,
                    },
                    scales: {
                        x: {
                            display: false                            
                        },
                        y: {
                            display: true                            
                        }
                    }                 
                }
            }
            this.chart = new Chart(canvas, config);
        },
        UpdateChart(){
            if(this.chart){
                this.chart.data.labels = this.headers;
                this.chart.data.datasets = [];                
                this.chart.data.datasets = [
                    {
                        data: this.data,
                        fill: false,
                        tension: 0.4,
                        borderColor: [
                            'rgba(75, 192, 192, 0.3)',
                            'rgba(255, 99, 132, 0.3)',
                            'rgba(135, 206, 235, 0.3)',
                            'rgba(205, 133, 63, 0.3)',
                            'rgba(128, 128, 128, 0.3)',
                            'rgba(0, 139, 139, 0.3)',
                            'rgba(154, 205, 50, 0.3)',
                            'rgba(54, 162, 235, 0.3)',
                            'rgba(244, 164, 96, 0.3)',
                            'rgba(0, 128, 0, 0.3)',
                            'rgba(255, 206, 86, 0.3)',
                            'rgba(70, 130, 180, 0.3)',
                            'rgba(218, 165, 32, 0.3)',
                            'rgba(153, 102, 255, 0.3)'
                        ]
                    }
                ];                        
                this.chart.update();
            }
        }
    }
}
</script>