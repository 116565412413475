import { AvaliadorAvaliado } from "./AvaliadorAvaliado";
import { PessoaJuridica } from "./shared/Pessoa";

export class Avaliador extends PessoaJuridica {

    avaliados: AvaliadorAvaliado[] = [];

    constructor(model?: Avaliador){

        super(model);

        if(!model)
            return;

        this.avaliados = model.avaliados;
    }
}