import { Avaliador, Diagnostico, DiagnosticoRespostaValor, SituacaoDiagnosticoResposta, Usuario } from ".";
import { Operacao } from "../shared";

export class DiagnosticoResposta implements Shared.IEntity {
    
    id: number = 0;
    diagnosticoId: number = 0;
    diagnostico!: Diagnostico;
    avaliadorId?: number;
    avaliador!: Avaliador;
    ongId?: number;
    ong?: string;
    situacaoId: number = 0;
    situacao!: SituacaoDiagnosticoResposta;
    
    criacao: Operacao = new Operacao();
    envio!: Operacao;

    respostas: DiagnosticoRespostaValor[] = [];

    constructor(model?: DiagnosticoResposta){

        if(!model)
            return;

        this.id = model.id;
        this.diagnosticoId = model.diagnosticoId;
        this.diagnostico = model.diagnostico;
        this.avaliadorId = model.avaliadorId;
        this.avaliador = model.avaliador;
        this.ongId = model.ongId;
        this.ong = model.ong;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;

        
        this.criacao = model.criacao;
        this.envio = model.envio;

        this.respostas = model.respostas;
    }
}