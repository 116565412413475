
import { PageBase } from "@/core/models/shared";
import { AlertSimpleErr, AutenticadorService } from "@/core/services/shared";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class MasterPage extends PageBase {
    @Prop() private icone!: string;
    @Prop() private titulo!: string;
    @Prop() private subtitulo!: string;

    drawer: boolean = false;
    temaEscuro: boolean = false;
    dialogAlterarSenha: boolean = false;

    menuOpts: any[] = [];

    mounted() {
        this.$vuetify.theme.dark = this.app.temaEscuro;
        this.CargaMenu();
    }

    CargaMenu(){        
        this.menuOpts = [
            { 
                name:'Diagnóstico', icon:'mdi-file', restrito: false, 
                items: [
                    { name:'Diagnóstico', icon:'mdi-file-chart', path:'/geral/diagnostico', restrito: this.app.ongId },
                    { name:'Respostas', icon:'mdi-file-find', path:'/geral/diagnosticoResposta', restrito:false },
                ]
            },
            { 
                name:'Relatórios', icon:'mdi-file-chart', restrito: this.app.ongId,
                items: [
                    { name:'Diagnóstico', icon:'mdi-factory', path:'/relatorio/diagnostico/configuracao', restrito:false }
                ]
            },
            { 
                name:'Auxiliar', icon:'mdi-cog', restrito: this.app.ongId,
                items: [
                    { name:'Avaliador', icon:'mdi-factory', path:'/auxiliar/avaliador', restrito:false },
                    { name:'Usuário', icon:'mdi-account-group', path:'/auxiliar/usuario', restrito:false }
                ]
            }
        ];        
    }

    GetAppVersion(){
        const pack = require('../../../package.json');
        return pack.version;
    }

    Restricao(perfilId: number[]){
        if(perfilId.length > 0)
        {
            if(perfilId.find(x => x == this.app.perfilId))
                return true;
            else   
                return false;
        }
        else{
            return true;
        }
    }

    MudarTema(){        
        new AutenticadorService().MudarTema(this.app.avaliadorId!).then(
            res => super.MudarTema(res.data),
            err => AlertSimpleErr("Aviso!", err)
        );
    };

    Logout() {
        localStorage.clear();
        this.$vuetify.theme.dark = false;
        this.$router.push({name: 'Login'});
    }
}
