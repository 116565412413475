import { AvaliadorAvaliado } from ".";

export class DiagnosticoAvaliado {

    avaliadoId: number = 0;
    avaliado!: AvaliadorAvaliado;
    diagnosticoId: number = 0;

    constructor(model?: DiagnosticoAvaliado){

        if(!model)
            return;

        this.avaliadoId = model.avaliadoId;
        this.avaliado = model.avaliado;
        this.diagnosticoId = model.diagnosticoId;
        
    }
}