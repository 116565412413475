import {http, httpHeader} from '../../ApiConfig'

export class EnderecoService {

    private _nomeControle: string = 'v1/endereco';
    
    public ObterPaises() {
        return http.get(`${this._nomeControle}/Pais`, httpHeader);
    }

    public ObterPaisPorId(paisId: number) {
        return http.get(`${this._nomeControle}/Pais/${paisId}`, httpHeader);
    }

    public ObterEstados() {
        return http.get(`${this._nomeControle}/Estado?$orderby=nome`, httpHeader);
    }

    public ObterEstadoPorId(estadoId: number) {
        return http.get(`${this._nomeControle}/Estado/${estadoId}`, httpHeader);
    }

    public ObterMunicipios(estadoId: number) {
        return http.get(`${this._nomeControle}/Estado/${estadoId}/Municipio?$orderby=nome`, httpHeader);
    }

    public ObterMunicipioPorId(municipioId: number) {
        return http.get(`${this._nomeControle}/Estado/Municipio/${municipioId}`, httpHeader);
    }

    public ObterPorCep(cep: string){
        return http.get(`${this._nomeControle}/cep/${cep}`, httpHeader);
    }
}