
import { DiagnosticoCampo } from '@/core/models/geral';
import { DiagnostivoRespostaValorModel } from '@/core/models/model/DiagnostivoRespostaValorModel';
import { PageBase } from '@/core/models/shared';
import { DiagnosticoRespostaService } from '@/core/services/geral';
import { AlertSimpleErr } from '@/core/services/shared';
import { AlertSimpleNotification } from '@/core/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class FormAnswer extends PageBase{
    @Prop() diagnosticoRespostaId!: number
    @Prop() ongId!: number
    @Prop() diagnosticoId!: number
    @Prop() avaliadorId!: number
    @Prop({required: true}) tipoId!: number
    @Prop({required: true}) campo!: DiagnosticoCampo;
    @Prop({required: true, default: true}) readonly!: boolean;
    @Prop({default: true}) load!: boolean;
    @Prop({default: false}) simple!: boolean;
    @Prop({default: false}) editar!: boolean;
    
    diagnosticoRespostaService: DiagnosticoRespostaService = new DiagnosticoRespostaService();

    resposta: DiagnostivoRespostaValorModel = new DiagnostivoRespostaValorModel();

    dialogAlert: boolean = false;
    textoAlert: string = "";

    dialogModificarUltimaNota: boolean = false;
    modificarUltimaNota: DiagnostivoRespostaValorModel = new DiagnostivoRespostaValorModel();

    mounted(){
        if(this.load){
            this.CarregarResposta();
        }
    }

    CarregarResposta(){
        this.diagnosticoRespostaService.ObterRespostaCampoDiagnostico(this.diagnosticoRespostaId, this.campo.id, this.ongId, this.diagnosticoId, this.avaliadorId).then(
            res => {
                let ret = res.data;
                if(ret.id > 0){
                    this.resposta = new DiagnostivoRespostaValorModel(res.data);                    
                }
                else{
                    this.resposta = new DiagnostivoRespostaValorModel();
                    this.resposta.campoId = this.campo.id;
                    this.resposta.diagnosticoRespostaId = this.diagnosticoRespostaId;                    
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }    

    Help(texto: string){
        this.dialogAlert = true;
        this.textoAlert = texto;
        // AlertSimple("Ajuda!", texto, "question");
    }

    RespostaZero(){
        this.resposta.valorNumerico = 0;
        this.SalvarResposta();
    }

    AbrirDialogUltimoValor(resposta: DiagnostivoRespostaValorModel){
        this.dialogModificarUltimaNota = true;
        this.modificarUltimaNota = resposta;
    }

    FecharDialogUltimoValor(){
        this.dialogModificarUltimaNota = false;
        this.modificarUltimaNota = new DiagnostivoRespostaValorModel();
    }

    SalvarResposta(){
        if(!this.readonly){
            //MULTIPLA ESCOLHA
            if(this.tipoId == 4){
                if(this.resposta.opcaoId != null && this.loading == false){
                    this.loading = true;
                    this.diagnosticoRespostaService.Responder(this.resposta).then(
                        res => {
                            if(this.resposta.id == 0)
                                this.resposta.id = res.data;
                            AlertSimpleNotification("Salvando...", "success");
                        },
                        err => AlertSimpleErr("Aviso!", err)
                    ).finally(() => {
                        this.loading = false;
                    })
                }
            }
            // ARANHA / NUMERO
            if(this.tipoId != 3){
                if(this.resposta.valorNumerico != null && this.loading == false){
                    this.loading = true;
                    this.diagnosticoRespostaService.Responder(this.resposta).then(
                        res => {
                            if(this.resposta.id == 0)
                                this.resposta.id = res.data;
                            AlertSimpleNotification("Salvando...", "success");
                        },
                        err => AlertSimpleErr("Aviso!", err)
                    ).finally(() => {
                        this.loading = false;
                    })
                }
            }
            // TEXTO
            else{
                if(this.resposta.valor.length > 0 && this.loading == false){
                    this.loading = true;
                    this.diagnosticoRespostaService.Responder(this.resposta).then(
                        res => {
                            if(this.resposta.id == 0)
                                this.resposta.id = res.data;
                        },
                        err => AlertSimpleErr("Aviso!", err)
                    ).finally(() => {
                        this.loading = false;
                    })
                }
            }
        }
    }
}
